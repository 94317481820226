// route constant
export const ROOT_PAGE = '/'
export const FORGOT_PAGE = '/forgot-password'
export const SIGNUP_PAGE = '/signup'
export const ACCESS_CODE_PAGE = '/access-code'
export const REQUEST_ACCESS_CODE_PAGE = '/request-access-code'
export const CHANGE_PASSWORD_PAGE = '/change-password'
export const EDIT_PROFILE_PAGE = '/edit-profile'
export const MANAGEUSER_PAGE = '/manage-user'
export const DASHBOARD_PAGE = '/dashboard'
export const BUCKET_PAGE = '/buckets'
export const BUCKET_DETAIL_PAGE = '/bucket/:id'
export const COACH_PLAYER_CONTENT_PAGE = '/player-content'
export const PLAYER_FILM_ROOM_PAGE = '/film-room'
export const FILM_ROOM_PAYMENT_STATUS = '/payment-status'
export const PLAYER_SOCIAL_WALL_PAGE = '/social-wall'
export const PLAYER_SOCIAL_COMMENT_PAGE = '/social-wall-comment/:type/:id'
export const PLAYER_SOCIAL_COMMENT_REPLY_PAGE = '/social-wall-comment/:type/:id/:postId'
export const PLAYER_SOCIAL_POST_PAGE = '/social-wall-post'
export const PLAYER_SOCIAL_COMMENT_LIST_PAGE = '/social-wall-comment-list/:postId'
export const PLAYER_CONTENT_DETAIL_PAGE = '/content-detail'

//Calendar
export const CALENDAR_PAGE = '/calendar'
export const SUBSCRIPTION_PAGE = '/subscription'
