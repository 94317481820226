import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme, styled } from '@mui/material/styles'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import * as Yup from 'yup'
import { Formik } from 'formik'

import httpClient from '@utils/httpClient'
import { SET_LOADER, SET_TOAST } from '@store/actions'

const Container = styled(Grid)({
    width: '70%',
    textAlign: 'center',
    margin: '35px auto',
})
const ContactForm = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMounted = useRef(true)
    const [showError, setShowError] = useState(null)

    const sendContactForm = async (values, actions) => {
        setShowError(null)
        dispatch({ type: SET_LOADER, data: true })
        const apiRes = await httpClient.post('/contacts', { ...values })
        dispatch({ type: SET_LOADER, data: false })
        if (apiRes && apiRes.ok) {
            setShowError(null)
            actions.resetForm()
            dispatch({
                type: SET_TOAST,
                data: {
                    type: 'success',
                    message: apiRes.message,
                },
            })
        } else if (apiRes.message) {
            setShowError(apiRes.message)
        }
    }

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <Container container direction="column" justifyContent="center">
            <Grid
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                sx={{ mb: 2 }}
            >
                <Typography variant="subtitle1">
                    Have something you want to talk to us about? Drop us a
                    message by filling out the form below. We try and answer all
                    messages within 48 hours.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Formik
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        message: '',
                    }}
                    validationSchema={Yup.object().shape({
                        first_name: Yup.string()
                            .max(255)
                            .required('First Name is required'),
                        last_name: Yup.string()
                            .max(255)
                            .required('Last Name is required'),
                        message: Yup.string().required('Message is required'),
                    })}
                    onSubmit={sendContactForm}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <FormControl
                                fullWidth
                                error={Boolean(
                                    touched.first_name && errors.first_name
                                )}
                                sx={{ mb: 2 }}
                            >
                                <TextField
                                    id="first_name"
                                    name="first_name"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.first_name}
                                    label="First Name"
                                    error={Boolean(
                                        touched.first_name && errors.first_name
                                    )}
                                    sx={{ boxShadow: theme.shadows[1] }}
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                error={Boolean(
                                    touched.last_name && errors.last_name
                                )}
                                sx={{ mb: 2 }}
                            >
                                <TextField
                                    id="last_name"
                                    name="last_name"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.last_name}
                                    label="Last Name"
                                    error={Boolean(
                                        touched.last_name && errors.last_name
                                    )}
                                    sx={{ boxShadow: theme.shadows[1] }}
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                error={Boolean(
                                    touched.message && errors.message
                                )}
                                sx={{ mb: 2 }}
                            >
                                <TextField
                                    id="message"
                                    name="message"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.message}
                                    label="Message"
                                    error={Boolean(
                                        touched.message && errors.message
                                    )}
                                    sx={{ boxShadow: theme.shadows[1] }}
                                    multiline
                                    rows={6}
                                />
                            </FormControl>
                            <Box>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Send Message
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Grid>
            {showError && (
                <Grid item xs={12} alignItems="center" justifyContent="left">
                    <Alert severity="error" sx={{ border: '1px solid #FF0000' }}>{showError}</Alert>
                </Grid>
            )}
        </Container>
    )
}

export default ContactForm
