const config = {
    basename: '',
    defaultPath: '/dashboard',
    gcp: {
        API_KEY: 'AIzaSyBB2xylWO8lQs6KwsPYEe3canq4phds7lA',
        STORAGE_BUCKET: 'sketchplay-be1a2.appspot.com',
    },
}

export default config
