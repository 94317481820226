import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
import { Grid, Box } from '@mui/material'
import SegmentSharpIcon from '@mui/icons-material/SegmentSharp';
import Logo from '@components/Logo'

const Header = ({ leftDrawer, openUserDrawer, leftDrawerToggle }) => {
    // const navigate = useNavigate()
    const { user } = useSelector((state) => state.user || {})
    const goBackPath = useSelector((state) => state.theme.goBackPath)

    return (
        <Grid container alignItems="center">
            <Grid item xs={2}>
                {/* {goBackPath && (
                    <ArrowBackIosNewIcon
                        onClick={() => navigate(goBackPath)}
                        className="pointer"
                    />
                )} */}
                {!leftDrawer &&
                    <Box sx={{ height: '33px', width: '33px' }} onClick={leftDrawerToggle} className="pointer">
                        <svg x='0px' y='0px' viewBox='0 0 36 36' style={{ fill: '#4e4e4e', width: '100%', height: 'auto' }}>
                            <g>
                                <path class='st0' d='M29.1,12.3H6.9c-1.1,0-1.9-0.9-1.9-1.9v0c0-1.1,0.9-1.9,1.9-1.9h22.2c1.1,0,1.9,0.9,1.9,1.9v0C31,11.4,30.1,12.3,29.1,12.3z' />
                                <path class='st0' d='M29.1,19.9H15.3c-1.1,0-1.9-0.9-1.9-1.9v0c0-1.1,0.9-1.9,1.9-1.9h13.8c1.1,0,1.9,0.9,1.9,1.9v0C31,19.1,30.1,19.9,29.1,19.9z' />
                                <path class='st0' d='M29.1,27.6h-6.9c-1.1,0-1.9-0.9-1.9-1.9v0c0-1.1,0.9-1.9,1.9-1.9h6.9c1.1,0,1.9,0.9,1.9,1.9v0C31,26.7,30.1,27.6,29.1,27.6z' />
                            </g>
                        </svg>
                    </Box>
                }
            </Grid>
            {/* <Grid item xs={8} container justifyContent="center">
                <Logo size="small" />
            </Grid> */}
            <Grid item xs={2} container justifyContent="flex-end" sx={{ ml: 'auto', }}>
                <Box onClick={openUserDrawer} sx={{
                    ml: 'auto',
                    '& .user-login-info figure': {
                        height: '45px',
                        width: '45px',
                        minWidth: '45px',
                        background: '#2a2e3e',
                        margin: 0,
                        color: '#fff',
                        fontSize: '22px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 600,
                        borderRadius: '50px',
                        marginRight: '0.6rem',
                        position: 'relative',
                        overflow: 'hidden',
                    },
                    '& .user-login-info figure img': {
                        width: '100%',
                        height: 'auto',
                        borderRadius: '50px',
                    },
                    '& .user-login-info': {
                        display: 'flex',
                        alignItems: 'center'
                    }
                }} className='pointer'>
                    <Box className='user-login-info' sx={{
                        '& .user-info h5': {
                            fontSize: '0.85rem',
                            color: '#212121',
                            fontWeight: 600,
                            lineHeight: 1.167,
                            letterSpacing: '0em',
                            margin: '0 0 0.2rem 0',
                            whiteSpace: 'nowrap',
                        },
                        '& .user-info p': {
                            margin: 0,
                            fontSize: '0.8rem'
                        },
                        "#userImg": {
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        },
                        span: {
                            "display": "block",
                            "fontSize": "20px",
                            "fontWeight": "bold",
                            "lineHeight": "45px",
                            "textAlign": "center",
                        }
                    }}>
                        <Box className='user-info' sx={{ paddingRight: '15px' }}>
                            <h5>{user?.first_name} {user?.last_name}</h5>
                            {user?.user_type === 'player' ?
                                <p>{"User"}</p> :
                                <p>{user?.user_type?.charAt(0)?.toUpperCase() + user?.user_type?.slice(1)}</p>}
                        </Box>
                        {user?.image ?
                            <figure><img id="userImg" src={user.image} alt="profile-img" /></figure> :
                            <Box component="span"
                                sx={{
                                    "backgroundColor": "#000000",
                                    "justifyContent": "center",
                                    "margin": "0",
                                    "height": "50px",
                                    "width": "50px",
                                    "minWidth": "50px",
                                    "color": "#ffffff",
                                    "borderRadius": "50rem",
                                    "position": "relative",
                                    "overflow": "hidden",
                                    "border": "3px solid #fff",
                                    "boxShadow": " 0 0 15px rgba(0, 0, 0, 0.25)",
                                    "textTransform": "uppercase"
                                }}>{`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`}
                            </Box>}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

Header.propTypes = {
    drawerToggle: PropTypes.func,
}

export default Header
