import * as actionTypes from '@store/actions'
import httpClient from '@utils/httpClient'

export const initialState = {
    user: null,
    token: null,
    userPlan: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TOKEN:
            httpClient.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${action.data}`
            return {
                ...state,
                token: action.data,
            }
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.data,
            }
        case actionTypes.SET_USER_HISTORIC_PLAN:
            return {
                ...state,
                userPlan: action.planData,
            }
        case actionTypes.LOGOUT_USER:
            delete httpClient.defaults.headers.common['Authorization']
            delete httpClient.defaults.headers.common['X-ORG-TOKEN']
            return {
                ...state,
                user: null,
                token: null,
            }
        default:
            return state
    }
}

export default reducer
