import { lazy } from 'react'
import { useSelector } from 'react-redux'
import { useRoutes, Navigate } from 'react-router-dom'

import config from '@src/config'
import * as pages from '@routes/constants'

import MainLayout from '@layouts/MainLayout'
import MinimalLayout from '@layouts/MinimalLayout'
import Loadable from '@components/Loadable'
import { Subscription } from '../views/subscription'

const AuthLogin = Loadable(lazy(() => import('@views/authentication')))
const ForgotPassword = Loadable(lazy(() => import('@views/forgotPassword')))
const Signup = Loadable(lazy(() => import('@views/signup')))
const AccessCode = Loadable(lazy(() => import('@views/accessCode')))
const RequestAccessCode = Loadable(
    lazy(() => import('@views/requestAccessCode'))
)
const Dashboard = Loadable(lazy(() => import('@views/dashboard')))
const Buckets = Loadable(lazy(() => import('@views/buckets')))
const BucketDetail = Loadable(lazy(() => import('@views/bucketDetail')))
const PlayerContent = Loadable(lazy(() => import('@views/playerContent')))
const FilmRoom = Loadable(lazy(() => import('@views/filmRoom')))
const PaymentStatus = Loadable(lazy(() => import('@views/paymentStatus')))
const SocialWall = Loadable(lazy(() => import('@views/socialWall')))
const SocialWallComment = Loadable(lazy(() => import('@views/socialWallComment')))
const SocialWallPost = Loadable(lazy(() => import('@views/socialWallPost')))
const SocialWallCommentList = Loadable(lazy(() => import('@views/socialWallCommentList')))
const CalendarView = Loadable(lazy(() => import('@views/calendar')))
const ContentDetailView = Loadable(lazy(() => import('@views/ContentDetail')))
const ChangePassword = Loadable(lazy(() => import('@components/ChangePassword')))
const EditProfile = Loadable(lazy(() => import('@components/EditProfile')))
const ManageUser = Loadable(lazy(() => import('@components/ManageUser')))
let socialWallUrl = false;

const routes = (isLoggedIn, hasAccessCode, userType) => [
    // Public Routes
    {
        path: pages.ROOT_PAGE,
        element: isLoggedIn ? (
            hasAccessCode ? (
                userType === 'player' ? <Navigate to={pages.PLAYER_FILM_ROOM_PAGE} /> :
                    <Navigate to={pages.COACH_PLAYER_CONTENT_PAGE} />
            ) : (
                <Navigate to={pages.ACCESS_CODE_PAGE} />
            )
        ) : (
            <MinimalLayout />
        ),
        children: [
            {
                path: pages.ROOT_PAGE,
                element: <AuthLogin />,
            },
            {
                path: pages.SIGNUP_PAGE,
                element: <Signup />,
            },
            {
                path: pages.FORGOT_PAGE,
                element: <ForgotPassword />,
            },
        ],
    },
    // Private Routes
    {
        path: pages.ROOT_PAGE,
        element: isLoggedIn ? (
            hasAccessCode ? (
                <MainLayout />
            ) : (
                <Navigate to={pages.ACCESS_CODE_PAGE} />
            )
        ) : (
            <Navigate to={pages.ROOT_PAGE} />
        ),
        children: [
            {
                path: pages.DASHBOARD_PAGE,
                element: <Dashboard />,
            },
            {
                path: pages.BUCKET_PAGE,
                element: <Buckets />,
            },
            {
                path: pages.BUCKET_DETAIL_PAGE,
                element: <BucketDetail />,
            },
            {
                path: pages.COACH_PLAYER_CONTENT_PAGE,
                element: <PlayerContent />,
            },
            {
                path: pages.PLAYER_CONTENT_DETAIL_PAGE,
                element: <ContentDetailView />,
            },
            {
                path: pages.PLAYER_FILM_ROOM_PAGE,
                element: <FilmRoom />,
            },
            {
                path: pages.FILM_ROOM_PAYMENT_STATUS,
                element: <PaymentStatus />,
            },
            {
                path: pages.SUBSCRIPTION_PAGE,
                element: <Subscription />,
            },
            {
                path: pages.CALENDAR_PAGE,
                element: <CalendarView />,
            },
            {
                path: pages.MANAGEUSER_PAGE,
                element: <ManageUser />,
            },
            {
                path: pages.CHANGE_PASSWORD_PAGE,
                element: <ChangePassword />,
            },
            {
                path: pages.EDIT_PROFILE_PAGE,
                element: <EditProfile />,
            },
        ],
    },

    {
        path: pages.PLAYER_SOCIAL_WALL_PAGE,
        element: socialWallUrl || isLoggedIn ? <SocialWall /> : <Navigate to={pages.ROOT_PAGE} />,
    },

    {
        path: pages.PLAYER_SOCIAL_COMMENT_PAGE,
        element: isLoggedIn ? <SocialWallComment /> : < Navigate to={pages.ROOT_PAGE} />,
    },
    {
        path: pages.PLAYER_SOCIAL_COMMENT_REPLY_PAGE,
        element: isLoggedIn ? <SocialWallComment /> : < Navigate to={pages.ROOT_PAGE} />,
    },
    {
        path: pages.PLAYER_SOCIAL_POST_PAGE,
        element: isLoggedIn ? <SocialWallPost /> : < Navigate to={pages.ROOT_PAGE} />,
    },
    {
        path: pages.PLAYER_SOCIAL_COMMENT_LIST_PAGE,
        element: isLoggedIn ? <SocialWallCommentList /> : < Navigate to={pages.ROOT_PAGE} />,
    },

    // Access Code Routes
    {
        path: pages.ROOT_PAGE,
        element:
            isLoggedIn && !hasAccessCode ? (
                <MinimalLayout />
            ) : (
                <Navigate to={pages.ROOT_PAGE} />
            ),
        children: [
            {
                path: pages.ACCESS_CODE_PAGE,
                element: <AccessCode />,
            },
            {
                path: pages.REQUEST_ACCESS_CODE_PAGE,
                element: <RequestAccessCode />,
            },
        ],
    },
    // 404 Routes
    {
        path: '*',
        element: <Navigate to={pages.ROOT_PAGE} />,
    },
]

const Routes = () => {
    const isLoggedIn = useSelector((state) => state.user.token)
    const user = useSelector((state) => state.user.user)
    const userType = useSelector((state) => state.user?.user?.user_type)
    const hasAccessCode = user?.has_org_approval || false
    const currentUrl = window.location.pathname;
    if (currentUrl == "/social-wall") {
        const param = new URLSearchParams(window.location.search);
        const token = param.get('token');
        const orgToken = param.get('orgToken');
        if (token && orgToken) {
            socialWallUrl = true;
        }

    }
    return useRoutes(routes(isLoggedIn, hasAccessCode, userType), config.basename)
}

export default Routes
