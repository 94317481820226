import { useDispatch, useSelector } from 'react-redux'

import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

import { SET_TOAST } from '@store/actions'

const ToastMessage = () => {
    const dispatch = useDispatch()
    const toastMsg = useSelector((state) => state.theme.toastMsg)
    const handleClose = () => dispatch({ type: SET_TOAST, data: null })

    return (
        <Snackbar
            open={toastMsg ? true : false}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity={toastMsg ? toastMsg.type : 'info'}
            >
                {toastMsg ? toastMsg.message || null : null}
            </Alert>
        </Snackbar>
    )
}

export default ToastMessage
