import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PageLoader from '@components/PageLoader'
import NotificationPopup from '@components/NotificationPopup'
import ToastMessage from '@components/ToastMessage'
import { Grid } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { getOrgData } from '@store/actions'
import { Box } from '@mui/system'

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    // minHeight: '100vh',
    '& .pointer': {
        cursor: 'pointer',
    },
    '& .textUnderline': {
        textDecoration: 'underline',
        textDecorationColor: 'secondary',
    },
    '& .d-flex': {
        display: 'flex',
    },
}))

const MinimalLayout = () => {
    const dispatch = useDispatch()
    const showLoader = useSelector((state) => state.theme.showLoader)
    const org = useSelector((state) =>
        state.org.org ? state.org.org.data : {}
    )

    const [getImage, setImage] = useState("https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRoUjtWGvtxMHPZxmFfv7_-dn3WhzdlfrCahVUDa7wBhDChQkVA")

    useEffect(() => {
        dispatch(getOrgData())
    }, [])

    return (
        <>
            <Helmet
                defaultTitle="App"
                titleTemplate="%s App"
                title={org.title}
                sx={{ minHeight: "100vh" }}
            />
            <Grid container>
                <Grid item xs={12} md={5} sx={{ backgroundColor: "#0063A8", p: '2.5vh', minHeight: "100vh" }}>
                    {/*  <Grid sx={{ height: "100%", }}
                        container
                        direction="column"
                        alignItems="center"
                    >
                        {
                            getImage &&
                            <Grid container sx={{
                                height: "100%",
                                '& .imgResponse': {
                                    height: "100%", width: '100%', objectFit: 'cover'
                                }
                            }}>
                                <Grid item xs={12} sx={{ height: "calc(50vh - 2.5vh)", minWidth: "100%", p: 2 }}>
                                    <img src={getImage} alt="test" className='imgResponse' />
                                </Grid>
                                <Grid item xs={12} sx={{ height: "calc(50vh - 2.5vh)", minWidth: "100%", p: 2 }}>
                                    <img src={getImage} alt="test1" className='imgResponse' />
                                </Grid>
                            </Grid>
                        }
                    </Grid>*/}
                </Grid>
                <Grid item xs={12} md={7} sx={{
                    backgroundColor: "#f2f4f7", minHeight: '100vh', display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Wrapper sx={{}}>
                        <Outlet />
                    </Wrapper>
                    <Box item xs={12} sx={{ textAlign: "center", mt: 'auto', mb: 3, svg: { mr: 2, cursor: "pointer", color: "#0063A8" } }}>
                        <a href={org?.fb_url} target='_blank'>
                            <FacebookIcon />
                        </a>
                        <a href={org?.twitter_url} target='_blank'>
                            <TwitterIcon />
                        </a>
                        <a href={org?.tiktok_url} target='_blank'>
                            <MusicNoteIcon />
                        </a>
                        <a href={org?.instagram_url} target='_blank'>
                            <InstagramIcon />
                        </a>
                        <a href={org?.hype_video_url} target='_blank'>
                            <YouTubeIcon />
                        </a>
                    </Box>
                </Grid>
            </Grid>
            {/* notification popup */}
            <NotificationPopup />

            {showLoader && <PageLoader />}
            <ToastMessage />
        </>
    )
}
export default MinimalLayout
