import { combineReducers } from 'redux'

// reducer import
import theme from '@reducers/theme'
import org from '@reducers/org'
import user from '@reducers/user'
import buckets from '@reducers/buckets'
import notification from '@reducers/notification'
import sketch from '@reducers/sketch'
import playerContent from '@reducers/playerContent'
import fileRoomContent from '@reducers/fileRoomContent'
import social from '@reducers/social'
import event from '@reducers/event'

const reducer = combineReducers({
    theme,
    org,
    user,
    buckets,
    notification,
    sketch,
    playerContent,
    fileRoomContent,
    social,
    event
})

export default reducer
