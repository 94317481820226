import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme, styled } from '@mui/material/styles'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

import * as Yup from 'yup'
import { Formik } from 'formik'

import httpClient from '@utils/httpClient'
import { SET_LOADER, SET_TOAST } from '@store/actions'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Divider, FormHelperText } from '@mui/material'
import { APP_MESSAGES, LIMIT_VALIDATION } from '../../utils/constants'


const ChangePassword = ({ UserDrawerClose, setStep }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMounted = useRef(true)
    const [showError, setShowError] = useState(null)
    const [showPassword, setShowPassword] = useState({})

    const handleShowPassword = (field) => {
        setShowPassword((prevState) => {
            return {
                ...prevState,
                [field]:
                    typeof showPassword[field] === 'undefined'
                        ? true
                        : !showPassword[field],
            }
        })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const submitForm = async (values, actions) => {
        setShowError(null)
        dispatch({ type: SET_LOADER, data: true })
        const apiRes = await httpClient.post('/auth/change-password', {
            ...values,
        })
        dispatch({ type: SET_LOADER, data: false })
        if (apiRes && apiRes.ok) {
            setShowError(null)
            actions.resetForm()
            dispatch({
                type: SET_TOAST,
                data: {
                    type: 'success',
                    message: apiRes.message,
                },
            })
            UserDrawerClose()
            setStep(0);
        } else if (apiRes.message) {
            setShowError(apiRes.message)
        }
    }

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    return (

        <Box sx={{
            background: '#fff',
            borderRadius: '5px',
            p: 3,
            // mb: 3,
            '& .MuiOutlinedInput-root': {
                border: '1px solid #dfe0df',
                boxShadow: '4px 5px 11px rgba(162, 188, 244, 0.4)',
                background: '#fff',
                '& .MuiOutlinedInput-input': {
                    background: 'transparent',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: '0',
                }
            },
        }}>
            <Grid
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                sx={{ mb: 2 }}
            >
                <Box>
                    <Typography variant='h4' sx={{ mt: 2 }}>Change Password</Typography>
                    <Divider sx={{ mt: 1.5, mb: 2 }}></Divider>
                </Box>
                <Typography variant="subtitle1">
                    {APP_MESSAGES.changePassDesc}
                </Typography>
                <Box
                    sx={{
                        border: '1px solid #228b22',
                        padding: '16px',
                        borderRadius: '4px',
                        backgroundColor: '#E8F5E9',
                        mt: 2,
                        li: {
                            mt: 0.5,
                        }
                    }}
                >
                    <Typography variant="subtitle1" gutterBottom sx={{ color: '#006400', ml: 1 }}>
                        Password Rules:
                    </Typography>
                    <ul>
                        <li>At least 6 characters.</li>
                        <li>A mixture of both uppercase and lowercase letters.</li>
                        <li>A mixture of letters and numbers.</li>
                        <li>Include at least one special character (e.g., ! @ # ?)</li>
                    </ul>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Formik
                    initialValues={{
                        oldPassword: '',
                        password: '',
                        passwordConfirmation: '',
                    }}
                    validationSchema={Yup.object().shape({
                        oldPassword: Yup.string()
                            .max(LIMIT_VALIDATION.oldPassValueLimit, LIMIT_VALIDATION.oldPassLimitMsg)
                            .required(LIMIT_VALIDATION.emptyCurrentPassMsg),
                        password: Yup.string()
                            .max(LIMIT_VALIDATION.newPassValueLimit, LIMIT_VALIDATION.newPassLimitMsg)
                            .matches(APP_MESSAGES.newPasswordFormat, 'Password must meet the required rules.')
                            .min(6)
                            .required(LIMIT_VALIDATION.emptyNewPassMsg),
                        passwordConfirmation: Yup.string()
                            .required(LIMIT_VALIDATION.emptyNewPassMsg)
                            .when('password', {
                                is: (val) =>
                                    val && val.length > 0 ? true : false,
                                then: Yup.string().oneOf(
                                    [Yup.ref('password')],
                                    'Both password need to be the same'
                                ),
                            }),
                    })}
                    onSubmit={submitForm}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <FormControl
                                fullWidth
                                error={Boolean(
                                    touched.oldPassword && errors.oldPassword
                                )}
                                sx={{ mb: 2 }}
                            >
                                <InputLabel htmlFor="oldPassword">
                                    Current Password
                                </InputLabel>
                                <OutlinedInput
                                    id="oldPassword"
                                    name="oldPassword"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.oldPassword}
                                    label="Current Password"
                                    error={Boolean(
                                        touched.oldPassword &&
                                        errors.oldPassword
                                    )}
                                    sx={{ boxShadow: theme.shadows[1] }}
                                    type={
                                        showPassword.oldPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>
                                                    handleShowPassword(
                                                        'oldPassword'
                                                    )
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge="end"
                                                size="large"
                                            >
                                                {showPassword.oldPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {Boolean(touched.oldPassword && errors.oldPassword) && (
                                    <FormHelperText error>
                                        {errors.oldPassword}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                fullWidth
                                error={Boolean(
                                    touched.password && errors.password
                                )}
                                sx={{ mb: 2 }}
                            >
                                <InputLabel htmlFor="password">
                                    New Password
                                </InputLabel>
                                <OutlinedInput
                                    id="password"
                                    name="password"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    label="New Password"
                                    error={Boolean(
                                        touched.password && errors.password
                                    )}
                                    sx={{ boxShadow: theme.shadows[1] }}
                                    type={
                                        showPassword.password
                                            ? 'text'
                                            : 'password'
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>
                                                    handleShowPassword(
                                                        'password'
                                                    )
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge="end"
                                                size="large"
                                            >
                                                {showPassword.password ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {Boolean(touched.password && errors.password) && (
                                    <FormHelperText error>
                                        {errors.password}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                fullWidth
                                error={Boolean(
                                    touched.passwordConfirmation &&
                                    errors.passwordConfirmation
                                )}
                                sx={{ mb: 2 }}
                            >
                                <InputLabel htmlFor="passwordConfirmation">
                                    Confirm New Password
                                </InputLabel>
                                <OutlinedInput
                                    id="passwordConfirmation"
                                    name="passwordConfirmation"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.passwordConfirmation}
                                    label="Confirm New Password"
                                    error={Boolean(
                                        touched.passwordConfirmation &&
                                        errors.passwordConfirmation
                                    )}
                                    sx={{ boxShadow: theme.shadows[1] }}
                                    type={
                                        showPassword.passwordConfirmation
                                            ? 'text'
                                            : 'password'
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>
                                                    handleShowPassword(
                                                        'passwordConfirmation'
                                                    )
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge="end"
                                                size="large"
                                            >
                                                {showPassword.passwordConfirmation ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {Boolean(touched.passwordConfirmation && errors.passwordConfirmation) && (
                                    <FormHelperText error>
                                        {errors.passwordConfirmation}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Typography variant='subtitle1' sx={{ color: "red", textAlign: "center", mb: 2 }}>
                                {showError}
                            </Typography>
                            <Box>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {APP_MESSAGES.saveNewPassBtnText}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Grid>
        </Box>
    )
}

export default ChangePassword
