import { Box, Button, Divider, FormControl, FormHelperText, TextField, Typography, Grid, InputLabel } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTextField from '../CustomTextField';
import { useFormik } from 'formik';
import * as Yup from "yup";
import MuiPhoneNumber from 'material-ui-phone-number';
import { useTheme } from '@mui/material/styles'
import httpClient from '@utils/httpClient'
import { useState } from 'react';
import { SET_LOADER, SET_TOAST, SET_USER, getMe } from '../../store/actions';
import { APP_MESSAGES } from '../../utils/constants';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';

const validationSchema = Yup.object({
    first_name: Yup.string().trim().required(APP_MESSAGES.emptyEditFirstNameMsg),
    last_name: Yup.string().trim().required(APP_MESSAGES.emptyEditLastNameMsg),
    email: Yup.string().trim().required(APP_MESSAGES.emptyEditEmailMsg).matches(/^\S+@\S+\.\S+$/, APP_MESSAGES.invalidEmailFormatMsg),
    // mobile: Yup.string().min(10).required(APP_MESSAGES.emptyEditMobMsg),
    bio: Yup.string().required(APP_MESSAGES.emptyEditBioMsg),
    image: Yup.string()
});


const EditProfile = ({ UserDrawerClose, setStep }) => {
    const { user } = useSelector((state) => state.user);
    const theme = useTheme()
    const dispatch = useDispatch()
    const [countryCode, setCountryCode] = useState("");
    const [imageChange, setImageChange] = useState(null);

    const handleSave = async (values, actions) => {
        dispatch({ type: SET_LOADER, data: true });
        const API_URL = "/auth/edit-profile"
        const formattedMobile = values.mobile
        const mobile = formattedMobile.replace(/[^0-9]/g, '')
        const phoneNumber = mobile.replace(`${countryCode}`, '');
        if (imageChange) {
            const API_URL = "/auth/change-profilePic"
            const formData = new FormData();
            formData.append('files', imageChange);
            const apiRes = await httpClient.post(API_URL, formData)
            if (apiRes && apiRes.ok) {
                dispatch({
                    type: SET_TOAST,
                    data: {
                        type: 'success',
                        message: apiRes.message,
                    },
                })
            } else {
                dispatch({ type: SET_LOADER, data: false })
                dispatch({
                    type: SET_TOAST,
                    data: {
                        type: 'error',
                        message: apiRes.message,
                    },
                })
            }
        }
        let obj = {
            first_name: values.first_name,
            last_name: values.last_name,
            // email: values.email,
            mobile: phoneNumber,
            // countryCode: countryCode,
            bio: values.bio,
        }
        const apiRes = await httpClient.post(API_URL, obj)
        if (apiRes && apiRes.ok) {
            dispatch({ type: SET_LOADER, data: false })
            dispatch({ type: SET_USER, data: apiRes.user })
            dispatch(getMe())
            dispatch({
                type: SET_TOAST,
                data: {
                    type: 'success',
                    message: apiRes.message,
                },
            })
            UserDrawerClose()
            setStep(0);
        } else {
            dispatch({ type: SET_LOADER, data: false })
            dispatch({
                type: SET_TOAST,
                data: {
                    type: 'error',
                    message: apiRes.message,
                },
            })
        }
    }
    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            mobile: "",
            bio: ""
        },
        validationSchema: validationSchema,
        onSubmit: handleSave
    });

    useEffect(() => {
        if (user) {
            formik.setFieldValue('first_name', user.first_name);
            formik.setFieldValue('last_name', user.last_name);
            formik.setFieldValue('email', user.email);
            formik.setFieldValue('mobile', user.mobile);
            formik.setFieldValue('bio', user.bio);
            formik.setFieldValue('image', user.image);
        }
    }, [user, formik.setFieldValue]);

    const handleImageChange = (e) => {
        setImageChange(e.target.files[0])
        const file = e.target.files[0];
        if (file) {
            formik.setFieldValue('image', URL.createObjectURL(file));
        }
    };
    const EditIconWrapper = styled(EditIcon)`
    position: absolute;
    top: 58px;
    right: 5px;
    color: #ffffff;
    background-color: #000000;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2 !important;;
`;
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Box
                    sx={{
                        background: '#fff',
                        borderRadius: '5px',
                        p: 3,
                        '& .MuiOutlinedInput-root': {
                            border: '1px solid #dfe0df',
                            boxShadow: '4px 5px 11px rgba(162, 188, 244, 0.4)',
                            background: '#fff',
                            '& .MuiOutlinedInput-input': {
                                background: 'transparent',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: '0',
                                boxShadow: 'none',
                            },
                        },
                        '& .MuiFormControl-root .MuiFormControl-root': {
                            boxShadow: 'none',
                        },
                        span: {
                            "display": "block",
                            "fontSize": "37px",
                            "fontWeight": "bold",
                            "lineHeight": "99px",
                            "textAlign": "center",
                            "height": "100px",
                            "width": "100px",

                        },
                        figure: {
                            "margin": "0",
                            "height": "100px",
                            "width": "100px",
                            "minWidth": "50px",
                            "borderRadius": "50rem",
                            "position": "relative",
                            "overflow": "hidden",
                            "border": "3px solid #fff",
                            "boxShadow": " 0 0 15px rgba(0, 0, 0, 0.25)",
                            left: '50%',
                            transform: 'translate(-50%)',
                        },
                        "#userImg": {
                            width: "100%",
                            height: "100%"
                        },
                        "#save": {
                            py: 1.5,
                            px: 3.5,
                            fontSize: 15,
                            fontWeight: 700,
                            mt: 2
                        }
                    }}>
                    {
                        user?.image ?
                            <Box sx={{ position: "relative" }}>
                                <figure>
                                    <label htmlFor="imageUpload">
                                        <img id="userImg" src={formik.values.image} alt="profile-img" />
                                        <EditIconWrapper />
                                    </label>
                                    <input
                                        id="imageUpload"
                                        name="image"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{ display: 'none' }}
                                    />
                                </figure>
                            </Box> :
                            (
                                <figure>
                                    <label htmlFor="imageUpload">
                                        {formik.values.image ? (
                                            <img id="userImg" src={formik.values.image} alt="profile-img" />
                                        ) : (
                                            <>
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        "backgroundColor": "#000000",
                                                        "justifyContent": "center",
                                                        "margin": "0",
                                                        "minWidth": "100px",
                                                        "color": "#ffffff",
                                                        "borderRadius": "50rem",
                                                        "position": "relative",
                                                        "overflow": "hidden",
                                                        "border": "3px solid #fff",
                                                        "boxShadow": "0 0 15px rgba(0, 0, 0, 0.25)",
                                                        "textTransform": "uppercase",
                                                        "height": "100px",
                                                        "width": "100px",
                                                        'lineHeight': '97px',
                                                        'marginLeft': '-3px',
                                                        'marginRight': 'auto',
                                                        'marginTop': "-3px",
                                                        'fontSize': '2rem',
                                                        'cursor': 'pointer',
                                                    }}
                                                >
                                                    {`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`}
                                                </Box>
                                                <EditIconWrapper />
                                            </>
                                        )}
                                        <input
                                            id="imageUpload"
                                            name="image"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />
                                    </label>
                                </figure>
                            )
                    }
                    <Box>
                        <Typography variant='h4' sx={{ mt: 2 }}>My Information</Typography>
                        <Divider sx={{ mt: 1.5, mb: 2 }}></Divider>
                    </Box>
                    <Box sx={{ backgroundColor: "transparent" }}>
                        <FormControl
                            fullWidth
                            error={Boolean(
                                formik.touched.first_name &&
                                formik.errors.first_name
                            )}
                            sx={{ my: 1 }}
                        >
                            <CustomTextField
                                id="first-name"
                                name='first_name'
                                placeholder='First Name'
                                variant="outlined"
                                value={formik.values.first_name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(
                                    formik.touched.first_name &&
                                    formik.errors.first_name
                                )}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        border: '1px solid #dfe0df',
                                    },
                                }}
                            />
                            {Boolean(
                                formik.touched.first_name && formik.errors.first_name
                            ) && (
                                    <FormHelperText error>
                                        {formik.errors.first_name}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(
                                formik.touched.last_name &&
                                formik.errors.last_name
                            )}
                            sx={{ my: 1 }}
                        >
                            <CustomTextField
                                id="last-name"
                                variant="outlined"
                                name="last_name"
                                placeholder='Last Name'
                                value={formik.values.last_name}
                                onBlur={formik.handleBlur}
                                shrink={false}
                                onChange={formik.handleChange}
                                error={Boolean(
                                    formik.touched.last_name &&
                                    formik.errors.last_name
                                )} sx={{
                                    '& .MuiOutlinedInput-root': {
                                        border: '1px solid #dfe0df',
                                    },
                                }}
                            />
                            {Boolean(
                                formik.touched.last_name && formik.errors.last_name
                            ) && (
                                    <FormHelperText error>
                                        {formik.errors.last_name}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(formik.touched.email && formik.errors.email)}
                            sx={{ my: 1, mb: 4 }}
                        >
                            <CustomTextField
                                id="email"
                                name="email"
                                placeholder='Email'
                                variant="outlined"
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                InputProps={{
                                    inputProps: { min: 0 }
                                }}
                                error={Boolean(
                                    formik.touched.email &&
                                    formik.errors.email
                                )}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        border: '1px solid #dfe0df',
                                    },
                                }}
                                disabled
                            />
                            {Boolean(
                                formik.touched.email && formik.errors.email
                            ) && (
                                    <FormHelperText error>
                                        {formik.errors.email}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(
                                formik.touched.mobile && formik.errors.mobile
                            )}
                            sx={{
                                ...theme.typography.customInput, '& .MuiOutlinedInput-notchedOutline': {
                                    border: '0',
                                    boxShadow: '4px 5px 11px rgba(162, 188, 244, 0.4)',
                                },
                                mt: "-10px",
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                    border: '1px solid #dfe0df',
                                },
                            }}
                        >
                            <MuiPhoneNumber
                                id="mobile"
                                name="mobile"
                                defaultCountry={'us'}
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                value={formik.values.mobile}
                                onChange={(val, c, t) => {
                                    console.log(c, c.dialCode)
                                    setCountryCode(c.dialCode)
                                }}
                                disabled
                                disableDropdown
                                error={Boolean(
                                    formik.touched.mobile && formik.errors.mobile
                                )}
                            />
                            {Boolean(
                                formik.touched.mobile && formik.errors.mobile
                            ) && (
                                    <FormHelperText error>
                                        {formik.errors.mobile}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(
                                formik.touched.bio && formik.errors.bio
                            )}
                        >
                            <TextField
                                id="bio"
                                name="bio"
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.bio}
                                error={Boolean(
                                    formik.touched.bio && formik.errors.bio
                                )}
                                sx={{ boxShadow: theme.shadows[1] }}
                                multiline
                                rows={6}
                            />
                            {Boolean(
                                formik.touched.bio && formik.errors.bio
                            ) && (
                                    <FormHelperText error>
                                        {formik.errors.bio}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <Button id="save" fullWidth variant="contained" type="submit">Save</Button>
                    </Box>
                </Box>
            </form >
        </>
    );
}

export default EditProfile;
