import { useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import { styled } from '@mui/material/styles'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import httpClient from '@utils/httpClient'
import { SET_LOADER, SET_TOAST } from '@store/actions'
import { charValidate } from '../../utils/functions'

const Container = styled(Card)({
    display: 'flex',
    flexDirection: 'row',
    flex: '1 0 auto',
    width: '100%',
    textDecoration: 'none',
    padding: 10,
    cursor: 'pointer',
})
const ContainerContent = styled(CardContent)({
    flex: '1 1 auto',
})
const BucketArrow = styled(Box)({
    display: 'flex',
    alignItems: 'center',
})

const FilmContentPlanCard = ({ plan }) => {
    const dispatch = useDispatch()

    const handlePlanSelection = async () => {
        dispatch({ type: SET_LOADER, data: true })
        const apiRes = await httpClient.post('/filmRoomPaymentLink', {
            plan_id: plan.id,
            redirect_url: encodeURIComponent(
                `${window.location.origin}/payment-status`
            ),
        })
        if (apiRes && apiRes.ok) {
            window.location.href = apiRes.data.payment_link
            return null
        } else {
            dispatch({ type: SET_LOADER, data: false })
            dispatch({
                type: SET_TOAST,
                data: {
                    type: 'error',
                    message:
                        apiRes.message ||
                        'Something went wrong. Please try again!',
                },
            })
        }
    }

    return (
        <Container onClick={handlePlanSelection}>
            <ContainerContent>
                <Typography
                    component="div"
                    variant="h4"
                    sx={{ display: 'flex' }}
                >
                    {plan.title}
                </Typography>
                <Typography variant="body1" sx={{ my: 1 }}>
                    {charValidate(plan.description, 30)}
                </Typography>
                <Typography variant="h3">
                    {plan.amount_currency} {plan.amount}
                </Typography>
            </ContainerContent>
            <BucketArrow>
                <IconButton aria-label="show detail">
                    <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
            </BucketArrow>
        </Container>
    )
}

export default FilmContentPlanCard
