import httpClient from '@utils/httpClient'

// action - theme reducer
export const SET_DRAWER = '@theme/SET_DRAWER'
export const SET_LOADER = '@theme/SET_LOADER'
export const SET_GOBACK = '@theme/SET_GOBACK'
export const SET_TOAST = '@theme/SET_TOAST'
export const goBack = (path) => {
    return async (dispatch) => {
        dispatch({ type: SET_GOBACK, data: path })
    }
}

// action - notification reducer
export const SET_NOTIFICATION_CONTENT = '@notification/SET_NOTIFICATION_CONTENT'

// action - org reducer
export const SET_ORG = '@user/SET_ORG'
export const getOrgData = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        let code = ''
        const host = window?.location?.hostname || ''
        // FOR LOCALHOST TESTING //
        if (host === 'localhost') {
            code = 'c2NyaXY=' // scriv (3)
            // code = 'NnBvaW50cw==' // 6points (2)
            // code = 'c2tldGNocGxheQ==' // sketchplay (1)
            // code = 'Z3JpZGlyb24=' // gridiron (4)
        }
        try {
            const res = await httpClient.post('/orgSettings', {
                host,
                code,
            })
            dispatch({ type: SET_ORG, data: res || null })
            dispatch({ type: SET_LOADER, data: false })
        } catch (error) {
            dispatch({ type: SET_LOADER, data: false })
            dispatch({
                type: SET_TOAST,
                data: {
                    type: 'error',
                    message: 'something went wrong',
                },
            })
        }

    }
}

// action - user reducer
export const SET_USER = '@user/SET_USER'
export const SET_USER_HISTORIC_PLAN = '@user/SET_USER_HISTORIC_PLAN'
export const SET_TOKEN = '@user/SET_TOKEN'
export const LOGOUT_USER = '@user/LOGOUT_USER'

// action - sketch reducer
export const SAVE_SVGDATA = '@sketch/SAVE_SVGDATA'
export const SET_SAVE_ACTION = '@sketch/SET_SAVE_ACTION'
export const SET_CLEAR_ACTION = '@sketch/SET_CLEAR_ACTION'

// action - buckets reducer
export const GET_BUCKET = '@buckets/GET_BUCKET'
export const SET_BUCKET = '@buckets/SET_BUCKET'
export const UPDATE_BUCKET = '@buckets/UPDATE_BUCKET'
export const getBucketListing = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/buckets?full=true')
        const buckets = res.data || []
        dispatch({ type: SET_BUCKET, data: buckets })
        dispatch({ type: SET_LOADER, data: false })
    }
}

// action - player content reducer
export const GET_PLAYER_CONTENT = '@playerContent/GET_PLAYER_CONTENT'
export const SET_PLAYER_CONTENT = '@playerContent/SET_PLAYER_CONTENT'
export const UPDATE_PLAYER_CONTENT = '@playerContent/UPDATE_PLAYER_CONTENT'
export const ADD_PLAYER_CONTENT = '@playerContent/ADD_PLAYER_CONTENT'
export const GET_PLAYER_CONTENT_CATEGORY =
    '@playerContent/GET_PLAYER_CONTENT_CATEGORY'
export const SET_PLAYER_CONTENT_CATEGORY =
    '@playerContent/SET_PLAYER_CONTENT_CATEGORY'
export const UPDATE_PLAYER_CONTENT_CATEGORY =
    '@playerContent/UPDATE_PLAYER_CONTENT_CATEGORY'
export const ADD_PLAYER_CONTENT_CATEGORY =
    '@playerContent/ADD_PLAYER_CONTENT_CATEGORY'
export const getPlayerContent = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/player-contents')
        const contents = res.data || []
        dispatch({ type: SET_PLAYER_CONTENT, data: contents })
        dispatch({ type: SET_LOADER, data: false })
    }
}
export const getPlayerContentCategories = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/player-content-categories')
        const categories = res.data || []
        dispatch({ type: SET_PLAYER_CONTENT_CATEGORY, data: categories })
        dispatch({ type: SET_LOADER, data: false })
    }
}

// action - Gym Section reducer
export const SET_FILM_ROOM_CONTENT = '@playerContent/SET_FILM_ROOM_CONTENT'
export const SET_FILM_ROOM_PLANS = '@playerContent/SET_FILM_ROOM_PLANS'
export const getFilmRoomContent = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/filmRoom')
        const contents = res.data || []
        dispatch({ type: SET_FILM_ROOM_CONTENT, data: contents })
        dispatch({ type: SET_LOADER, data: false })
    }
}
export const getFilmRoomPlans = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/filmRoomPlans')
        const plans = res.data || []
        dispatch({ type: SET_FILM_ROOM_PLANS, data: plans })
        dispatch({ type: SET_LOADER, data: false })
    }
}
export const getMe = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/users/me')
        const { user } = res
        if (user) {
            dispatch({ type: SET_USER, data: user })
        }
        dispatch({ type: SET_LOADER, data: false })
    }
}

export const ADD_PLAYER_SESSION = '@playerContent/ADD_PLAYER_SESSION'

export const SET_PLAYER_SESSION_ID = '@playerContent/SET_PLAYER_SESSION_ID'
export const getPlayerContentById = (id) => {
    return async (dispatch) => {
        //dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/player-contents/' + id)
        const contents = res.data || []
        dispatch({ type: SET_PLAYER_SESSION_ID, data: contents })
        //dispatch({ type: SET_LOADER, data: false })
    }
}

export const SET_SOCIAL_POST = '@social/SET_SOCIAL_POST'
export const CREATE_SOCIAL_POST = '@social/CREATE_SOCIAL_POST'
export const CREATE_SOCIAL_COMMENT = '@social/CREATE_SOCIAL_COMMENT'
export const CREATE_SOCIAL_LIKE = '@social/CREATE_SOCIAL_LIKE'
export const SET_SOCIAL_COMMENT_LIST = '@social/SET_SOCIAL_COMMENT_LIST'
export const SET_SOCIAL_POSTID = '@social/SET_SOCIAL_POSTID'

export const getSocialPost = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/social-posts')
        const contents = res.data || []
        dispatch({ type: SET_SOCIAL_POST, data: contents })
        dispatch({ type: SET_LOADER, data: false })
    }
}

export const getHistoricPlan = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/historic-plan')
        dispatch({ type: SET_USER_HISTORIC_PLAN, planData: res?.data })
        dispatch({ type: SET_LOADER, data: false })
    }
}

export const getSocialPostId = (postId) => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/social-posts/' + postId)
        const contents = res.data?.post || {}
        dispatch({ type: SET_SOCIAL_POSTID, data: contents })
        dispatch({ type: SET_LOADER, data: false })
    }
}

export const getSocialCommentsListByPostId = (postId) => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/social-post-comments/' + postId)
        const contents = res.data || []
        dispatch({ type: SET_SOCIAL_COMMENT_LIST, data: contents })
        dispatch({ type: SET_LOADER, data: false })
    }
}

export const SET_PLAYER_EVENT = '@event/SET_PLAYER_EVENT'
export const ADD_PLAYER_EVENT = '@event/ADD_PLAYER_EVENT'
export const EDIT_PLAYER_EVENT = '@event/EDIT_PLAYER_EVENT'

export const getAllEvents = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/allEvents')
        const formattedEventList = res.data.map(event => ({
            ...event,
            start_date: new Date(event.start_date),
            end_date: new Date(event.end_date),
        }));
        const events = formattedEventList || []
        dispatch({ type: SET_PLAYER_EVENT, data: events })
        dispatch({ type: SET_LOADER, data: false })
    }
}

export const SET_EVENT_LOCATIONS = '@event/SET_EVENT_LOCATIONS'
export const ADD_EVENT_LOCATIONS = '@event/ADD_EVENT_LOCATIONS'
export const UPDATE_EVENT_LOCATIONS = '@event/UPDATE_EVENT_LOCATIONS'

export const getEventLocations = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.get('/event-locations')
        const locations = res.location || []
        dispatch({ type: SET_EVENT_LOCATIONS, data: locations })
        dispatch({ type: SET_LOADER, data: false })
    }
}
